import { Component, Inject, Vue } from 'vue-property-decorator';
import { VERSION } from '@/constants';
import LoginService from '@/account/login.service';
import AccountService from '@/account/account.service';
import TranslationService from '@/locale/translation.service';
import ElbResourceService from '@/entities/elb-resource/elb-resource.service';
import { TOKEN } from '@/constants';
import UserActivityService from '@/entities/user-activity/user-activity.service';
import ToolbarUserMenu from '@/shared/component/toolbar-user-menu.vue';
import { utils } from '@/shared/utils';
import ArticleService from '@/entities/article/article.service';

@Component({
  components: {
    ToolbarUserMenu,
  },
})
export default class JhiNavbar extends Vue {
  @Inject('loginService')
  private loginService: () => LoginService;

  @Inject('translationService')
  private translationService: () => TranslationService;

  @Inject('elbResourceService')
  private elbResourceService: () => ElbResourceService;

  @Inject('articleService')
  private articleService: () => ArticleService;

  @Inject('accountService')
  private accountService: () => AccountService;

  @Inject('userActivityService')
  private userActivityService: () => UserActivityService;

  public version = VERSION ? 'v' + VERSION : '';
  private currentLanguage = this.$store.getters.currentLanguage;
  private languages: any = this.$store.getters.languages;
  private hasAnyAuthorityValue = false;
  public toggleMini = false;
  public sidebarMenu = true;
  public menuItems = [];
  private resourceItems = [];
  public selected = 0;

  async created() {
    this.translationService().refreshTranslation(this.currentLanguage);
    const token = localStorage.getItem(TOKEN);
    if (token) {
      this.accountService()
        .retrieveAccount()
        .then(() => {
          this.setMenuItems();
        });

      const paginationQuery = {
        page: 0,
        size: 4,
        sort: [],
      };

      this.elbResourceService()
        .retrieve(paginationQuery)
        .then((res) => {
          this.resourceItems = res.data;
        });
    }
  }

  public get mini() {
    return this.$vuetify.breakpoint.smAndDown || this.toggleMini;
  }

  public addDashboard() {
    if (this.accountService().isReporter()) {
      this.menuItems.push({
        title: this.$t('global.menu.entities.dashboard'),
        icon: 'mdi-monitor-dashboard',
        items: [
          // { title: this.$t('global.menu.entities.default'), routeName:"Dashboard", icon:"mdi-chart-box-outline" },
          // { title: this.$t('global.menu.entities.reseller'), routeName:"DashboardReseller", icon:"mdi-home-group" },
          { title: 'Users', routeName: 'UserActivityReport', icon: 'mdi-table-account' },
          { title: 'Platform Viewers', routeName: 'HybridViewerReport', icon: 'mdi-account-arrow-right' },
        ],
      });
    }
  }

  public launchNewTab(link) {
    utils.launchNewTab(link);
  }

  public setMenuItems() {
    this.menuItems = [];
    if (this.accountService().isAdmin()) {
      this.addDashboard();
      if (process.env.NODE_ENV !== 'production') {
        this.menuItems.push({
          title: this.$t('global.menu.entities.home'),
          routeName: 'Home',
          icon: 'mdi-home',
        });
      }
      this.menuItems.push({
        title: this.$t('global.menu.entities.apps'),
        routeName: 'ProductVersionApps',
        icon: 'mdi-application-settings-outline',
      });
      this.menuItems.push({ title: this.$t('global.menu.entities.reseller'), routeName: 'Reseller', icon: 'mdi-home-group' });
      this.menuItems.push({ title: this.$t('global.menu.entities.organization'), routeName: 'Organization', icon: 'mdi-domain' });
      this.menuItems.push({
        title: this.$t('global.menu.entities.applicationUser'),
        routeName: 'ApplicationUser',
        icon: 'mdi-account-group',
      });
      this.menuItems.push({
        title: this.$t('global.menu.entities.elbSubscription'),
        routeName: 'ElbSubscription',
        icon: 'mdi-playlist-check',
      });
      // this.menuItems.push({ title: this.$t('global.menu.entities.elbSubscription'), routeName:"ElbSubscription", icon:"mdi-playlist-check" });
      this.menuItems.push({ title: 'Payment Link', routeName: 'PaymentLink', icon: 'mdi-credit-card-outline' });
      this.menuItems.push({ title: this.$t('global.menu.entities.product'), routeName: 'Product', icon: 'mdi-application-settings' });
      this.menuItems.push({
        title: this.$t('global.menu.entities.productVersion'),
        routeName: 'ProductVersion',
        icon: 'mdi-star-circle-outline',
      });
      this.menuItems.push({ title: this.$t('global.menu.entities.plan'), routeName: 'Plan', icon: 'mdi-playlist-check' });
      this.menuItems.push({ title: 'User Add-on', routeName: 'Addon', icon: 'mdi-account-multiple-plus-outline' });
      this.menuItems.push({
        title: this.$t('global.menu.entities.planFeature'),
        routeName: 'PlanFeature',
        icon: 'mdi-order-bool-ascending-variant',
      });
      this.menuItems.push({
        title: this.$t('global.menu.entities.article'),
        routeName: 'Article',
        icon: 'mdi-book',
      });
      this.menuItems.push({
        title: this.$t('global.menu.entities.certificate'),
        routeName: 'Certificate',
        icon: 'mdi-book',
      });
      this.menuItems.push({ title: this.$t('global.menu.entities.elbResource'), routeName: 'ElbResource', icon: 'mdi-semantic-web' });
      this.menuItems.push({
        title: 'Developer',
        icon: 'mdi-webhook',
        items: [
          { title: this.$t('global.menu.entities.apiKey'), routeName: 'ApiKey', icon: 'mdi-shield-key-outline' },
          { title: this.$t('global.menu.entities.webHookVersion'), routeName: 'WebHookVersion', icon: 'mdi-counter' },
          { title: this.$t('global.menu.entities.webHookEvent'), routeName: 'WebHookEvent', icon: 'mdi-calendar-multiple-check' },
          { title: this.$t('global.menu.entities.webHookEndpoint'), routeName: 'WebHookEndpoint', icon: 'mdi-webhook' },
          { title: this.$t('global.menu.entities.elbEvent'), routeName: 'ElbEvent', icon: 'mdi-calendar-alert' },
        ],
      }); // view-list-outline
      this.menuItems.push({ title: 'Application Settings', routeName: 'Server', icon: 'mdi-cog-outline' });
      this.menuItems.push({ title: 'Audit Logs', routeName: 'AuditLogsComponent', icon: 'mdi-view-list-outline' });
      this.menuItems.push({ title: 'Regions', routeName: 'Region', icon: 'mdi-map-marker-multiple' });
      this.menuItems.push({ title: 'Scheduled Jobs', routeName: 'JobScheduler', icon: 'mdi-file-tree' });
      this.menuItems.push({ title: 'Import User', routeName: 'ImportUserComponent', icon: 'mdi-account-plus-outline' });
      this.menuItems.push({ title: 'Trial', routeName: 'TrialCreate', icon: 'mdi-new-box' });
      this.menuItems.push({ title: this.$t('global.menu.entities.encode'), routeName: 'Encode', icon: 'mdi-code-json' });
      return;
    } else if (this.accountService().isOrgAdmin()) {
      this.menuItems.push({
        title: this.$t('global.menu.entities.apps'),
        routeName: 'ProductVersionApps',
        icon: 'mdi-application-settings-outline',
      });
      this.menuItems.push({
        title: this.$t('global.menu.entities.applicationUser'),
        routeName: 'ApplicationUser',
        icon: 'mdi-account-group',
      });
      this.menuItems.push({ title: this.$t('global.menu.entities.setting'), routeName: 'Setting', icon: 'mdi-cog' });

      return;
    } else if (this.accountService().isAccountManager()) {
      this.menuItems.push({
        title: this.$t('global.menu.entities.applicationUser'),
        routeName: 'ApplicationUser',
        icon: 'mdi-account-group',
      });
      this.menuItems.push({ title: this.$t('global.menu.entities.setting'), routeName: 'Setting', icon: 'mdi-cog' });

      return;
    } else if (this.accountService().isAuthor() || this.accountService().isStudentUser()) {
      this.menuItems.push({
        title: this.$t('global.menu.entities.apps'),
        routeName: 'ProductVersionApps',
        icon: 'mdi-application-settings-outline',
      });
    } else if (this.accountService().isFulfilment() || this.accountService().isFulfilmentReadOnly()) {
      this.addDashboard();
      this.menuItems.push({ title: this.$t('global.menu.entities.reseller'), routeName: 'Reseller', icon: 'mdi-home-group' });
      this.menuItems.push({
        title: this.$t('global.menu.entities.apps'),
        routeName: 'ProductVersionApps',
        icon: 'mdi-application-settings-outline',
      });
      this.menuItems.push({ title: this.$t('global.menu.entities.organization'), routeName: 'Organization', icon: 'mdi-domain' });
      this.menuItems.push({
        title: this.$t('global.menu.entities.applicationUser'),
        routeName: 'ApplicationUser',
        icon: 'mdi-account-group',
      });
      this.menuItems.push({
        title: this.$t('global.menu.entities.elbSubscription'),
        routeName: 'ElbSubscription',
        icon: 'mdi-playlist-check',
      });
      this.menuItems.push({ title: 'Payment Link', routeName: 'PaymentLink', icon: 'mdi-credit-card-outline' });
      if (this.accountService().isFulfilment()) {
        this.menuItems.push({ title: 'Import User', routeName: 'ImportUserComponent', icon: 'mdi-account-plus-outline' });
      }
      this.menuItems.push({ title: 'Trial', routeName: 'TrialCreate', icon: 'mdi-new-box' });
    } else if (this.accountService().isReseller()) {
      this.menuItems.push({ title: this.$t('global.menu.entities.organization'), routeName: 'Organization', icon: 'mdi-domain' });
      this.menuItems.push({
        title: this.$t('global.menu.entities.applicationUser'),
        routeName: 'ApplicationUser',
        icon: 'mdi-account-group',
      });
      this.menuItems.push({
        title: this.$t('global.menu.entities.elbSubscription'),
        routeName: 'ElbSubscription',
        icon: 'mdi-playlist-check',
      });
      this.menuItems.push({ title: this.$t('global.menu.entities.emailTemplate'), routeName: 'EmailTemplate', icon: 'mdi-email' });
      this.menuItems.push({
        title: this.$t('global.menu.entities.customTrialForm'),
        routeName: 'CustomTrialForm',
        icon: 'mdi-form-textbox-password',
      });
      this.menuItems.push({ title: 'Trial', routeName: 'TrialCreate', icon: 'mdi-new-box' });
      this.menuItems.push({ title: this.$t('global.menu.entities.setting'), routeName: 'Setting', icon: 'mdi-cog' });

      return;
    } else {
      // this.menuItems.push({ title: this.$t('global.menu.home'), routeName:"Home", icon:"mdi-home" });
      // this.menuItems.push({ title: this.$t('global.menu.entities.apps'), routeName:"ProductVersionApps", icon:"mdi-application-settings" });

      return;
    }
  }

  public subIsActive(input) {
    const paths = Array.isArray(input) ? input : [input];
    return paths.some((path) =>
       this.$route.path.indexOf(path) === 0 // current path starts with this path string
    );
  }

  public changeLanguage(newLanguage: string): void {
    this.translationService().refreshTranslation(newLanguage);
  }

  public isActiveLanguage(key: string): boolean {
    return key === this.$store.getters.currentLanguage;
  }

  public logout(): void {
    this.userActivityService()
      .createEvent(this.$store.getters.account, this.$store.getters.currentLocation, 'portal logout', 'loggedout')
      .finally(() => {
        this.loginService()
          .logout()
          .then(() => {
            this.$store.commit('logout');
            this.$router.push('/');
          });
      });
  }

  public accountSettings() {
    this.$router.push({
      name: 'Profile',
    });
  }

  public openLogin(): void {
    // this.loginService().login();
    this.$router.push({
      name: 'Signin',
    });
  }

  public get authenticated(): boolean {
    return this.$store.getters.authenticated;
  }

  public hasAnyAuthority(authorities: any): boolean {
    this.accountService()
      .hasAnyAuthorityAndCheckAuth(authorities)
      .then((value) => {
        this.hasAnyAuthorityValue = value;
      });
    return this.hasAnyAuthorityValue;
  }

  public get swaggerEnabled(): boolean {
    return this.$store.getters.activeProfiles.indexOf('swagger') > -1;
  }

  public get inProduction(): boolean {
    return this.$store.getters.activeProfiles.indexOf('prod') > -1;
  }

  public get userAccount(): string {
    return this.$store.getters.account ? this.$store.getters.account : null;
  }

  public get currentUser(): string {
    return this.$store.getters.account ? this.$store.getters.account.firstName + ' ' + this.$store.getters.account.lastName : '';
  }
}
